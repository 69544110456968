
// import { marked } from 'marked'
import { mapGetters } from 'vuex'
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
// import BtnPrimary from './btn/BtnPrimary.vue'
// import Times from '@/components/parts/shapes/Times.vue'
export default {
  components: {
    // BtnPrimary,
    // Times,
  },
  data() {
    return {
      analyticsManager: {},
      showPrompt: false,
      showModal: false,
      gtm: {},

      essential: {
        id: 'site',
      },
      analytics: {
        id: '_ga',
        value: true,
      },

      // cookies: {
      //   heading: 'We use cookies to improve your experience.<br> By using this site you accept our <NuxtLink to="pricvacy">Policy Policy</NuxtLink>.',
      //   body: 'Please manage your cookie choices by switching the consent toggles on or off below.',
      //   accept: 'Accept',
      //   manage: 'Manage',
      //   delete: 'Delete All',
      //   essential: 'Essential Cookies',
      //   analytics: 'Analytics Cookies',
      // },
    }
  },
  computed: {
    ...mapGetters({
      cookies: 'getAnalytics',
    }),
    body() {
      if (this.cookies.body) {
        // return marked.parse(this.cookies.body)
        return this.cookies.body
      }
      return null
    },
  },
  created() {
    if (this.cookies) {
      this.$nuxt.$on('manage-cookies', () => {
        this.showModal = true
      })
    }
  },
  mounted() {
    if (this.cookies) {
      if (this.$cookies.get(this.essential.id)) {
        // if essential, check analytics
        if (this.$cookies.get(this.analytics.id)) {
          this.startAnalytics()
        } else {
          // no analytics
          this.analytics.value = false
        }
      } else {
        this.showPrompt = true
      }
    }
  },
  methods: {
    acceptCookies() {
      // called on accept
      this.setSiteCookie()
      this.startAnalytics()
      this.showPrompt = false
    },

    saveCookies() {
      // save site cookie incase not already set (set from modal)
      if (!this.$cookies.get(this.essential.id)) {
        this.setSiteCookie()
        this.showPrompt = false
      }

      if (this.analytics.value) {
        // save analytics cookie (start analytics) if not already added
        if (!this.$cookies.get(this.analytics.id)) {
          this.startAnalytics()
        }
        // remove analytics cookie (if exists)
      } else if (this.$cookies.get(this.analytics.id)) this.removeAnalyticsCookie()

      this.showModal = false
    },

    deleteCookies() {
      Object.keys(this.$cookies.getAll()).forEach((cookie) => {
        this.$cookies.remove(cookie)
      })
      this.reload()
    },

    startAnalytics() {
      // Google Analytics 4 property !!
      this.analytics.value = true
      this.analyticsManager = Analytics({
        plugins: [
          googleAnalytics({
            measurementIds: [this.$config.GOOGLE_ANALYTICS],
          }),
        ],
      })
      // fire pageview event
      this.analyticsManager.page()
    },

    setSiteCookie() {
      this.$cookies.set(this.essential.id, true, {
        path: '/',
        expires: new Date(new Date().getTime() + 1000 * 3600 * 240 * 120),
      })
    },

    removeSiteCookie() {
      this.$cookies.remove(this.essential.id)
    },

    removeAnalyticsCookie() {
      this.$cookies.remove(this.analytics.id)
      this.analyticsManager.plugins.disable(['google-analytics'])
      this.analyticsManager = null
      this.reload()
    },

    reload() {
      window.location.reload()
    },
  },
}
