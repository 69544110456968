import { gql } from 'graphql-tag'

export const ImageFragment = gql`
  fragment ImageFragment on UploadFileEntityResponse {
    data {
      id
      attributes {
        name
        formats
        ext
        url
        alternativeText
        provider
        width
        height
        mime
        caption
      }
    }
  }
`

export const LinkFragment = gql`
  fragment LinkFragment on ComponentPartsLink {
    label
    url
  }
`

export const MultipleImagesFragment = gql`
  fragment MultipleImagesFragment on UploadFileRelationResponseCollection {
    data {
      attributes {
        name
        formats
        ext
        url
        alternativeText
        provider
        width
        height
      }
    }
  }
`

export const ButtonFragment = gql`
  fragment ButtonFragment on ComponentPartsButton {
    label
    url
  }
`

export const VideoLocalFragment = gql`
  fragment VideoLocalFragment on ComponentPartsVideoLocal {
    webm {
      ... on UploadFileEntityResponse {
        ...ImageFragment
      }
    }
    mp4 {
      ... on UploadFileEntityResponse {
        ...ImageFragment
      }
    }
    poster {
      ...ImageFragment
    }
    caption
  }
`

export const VideoEmbedFragment = gql`
  fragment VideoEmbedFragment on ComponentPartsVideoEmbed {
    embedCode
    source
    poster {
      ...ImageFragment
    }
    caption
  }
`
