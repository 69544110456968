export default {
  methods: {
    isValidVariable(_variable) {
      let valid = false
      if (typeof _variable !== 'undefined') {
        if (_variable !== 'null' && _variable !== null) {
          if (_variable !== '') {
            valid = true
          }
        }
      }
      return valid
    },
    hasWebpVersion(media, size) {
      let hasWebp = false
      if (this.isValidVariable(media?.formats)) {
        if (this.isValidVariable(media?.formats[size]?.webp?.url)) {
          hasWebp = true
        }
      }
      return hasWebp
    },
    outputWebpVersion(media, size) {
      let img = ''
      const defaultSizes = ['massive', 'xlarge', 'large', 'medium', 'small', 'tiny']
      if (this.isValidVariable(media)) {
        if (this.isValidVariable(media.url)) {
          img = media.url
          if (this.isValidVariable(media.formats)) {
            if (this.isValidVariable(media.formats[size])) {
              img = media.formats[size].url
              if (this.isValidVariable(media.formats[size].webp)) {
                // show this size
                img = media.formats[size].webp.url
              } else {
                // cascade down other available sizes
                for (let i = 0; i < defaultSizes.length; i++) {
                  if (this.isValidVariable(media.formats[defaultSizes[i]])) {
                    if (this.isValidVariable(media.formats[defaultSizes[i]].webp)) {
                      img = media.formats[defaultSizes[i]].webp.url
                    }
                  }
                }
              }
            }
          }
        }
      }
      return this.setAssetDomain(img)
    },
    outputImage(media, size) {
      let img = ''
      const defaultSizes = ['massive', 'xlarge', 'large', 'medium', 'small', 'tiny']
      if (this.isValidVariable(media)) {
        if (this.isValidVariable(media.url)) {
          img = media.url
          if (this.isValidVariable(media.formats)) {
            if (this.isValidVariable(media.formats[size])) {
              // show this size
              img = media.formats[size].url
            } else {
              // cascade down other available sizes
              for (let i = 0; i < defaultSizes.length; i++) {
                if (this.isValidVariable(media.formats[defaultSizes[i]])) {
                  img = media.formats[defaultSizes[i]].url
                  break
                }
              }
            }
          }
        }
      }
      return this.setAssetDomain(img)
    },
    outputImageWebpSrcset(media, sizes) {
      const srcset = []
      const defaultSizes = {
        massive: 2000,
        xlarge: 1600,
        large: 1000,
        medium: 750,
        small: 500,
        tiny: 300,
      }
      if (this.isValidVariable(media)) {
        if (this.isValidVariable(media.formats)) {
          for (let i = 0; i < sizes.length; i++) {
            if (this.isValidVariable(media.formats[sizes[i]])) {
              if (this.isValidVariable(media.formats[sizes[i]].webp)) {
                srcset.push(`${this.setAssetDomain(media.formats[sizes[i]].webp.url)} ${defaultSizes[sizes[i]]}w`)
              }
            }
          }
        }
      }
      return srcset.join(', ')
    },
    outputImageSrcset(media, sizes) {
      const srcset = []
      const defaultSizes = {
        massive: 2000,
        xlarge: 1600,
        large: 1000,
        medium: 750,
        small: 500,
        tiny: 300,
      }
      if (this.isValidVariable(media)) {
        if (this.isValidVariable(media.formats)) {
          for (let i = 0; i < sizes.length; i++) {
            if (this.isValidVariable(media.formats[sizes[i]])) {
              srcset.push(`${this.setAssetDomain(media.formats[sizes[i]].url)} ${defaultSizes[sizes[i]]}w`)
            }
          }
        }
      }
      return srcset.join(', ')
    },
    outputImageWidth(media) {
      let value = 0
      if (this.isValidVariable(media)) {
        if (this.isValidVariable(media.width)) {
          value = media.width
          if (this.isValidVariable(media.formats)) {
            if (this.isValidVariable(media.formats.small)) {
              if (this.isValidVariable(media.formats.small.width)) {
                value = media.formats.small.width
              }
            }
          }
        }
      }
      return value
    },
    outputImageHeight(media) {
      let value = 0
      if (this.isValidVariable(media)) {
        if (this.isValidVariable(media.height)) {
          value = media.height
          if (this.isValidVariable(media.formats)) {
            if (this.isValidVariable(media.formats.small)) {
              if (this.isValidVariable(media.formats.small.height)) {
                value = media.formats.small.height
              }
            }
          }
        }
      }
      return value
    },
    isVideo(media) {
      let isVideo = false
      if (this.isValidVariable(media)) {
        if (this.isValidVariable(media.mime)) {
          isVideo = media.mime === 'video/mp4' || media.mime === 'video/webm'
        }
      }
      return isVideo
    },
    setAssetDomain(img) {
      let url = img
      if (this.$config.CDN_URL && this.$config.ASSET_URL) {
        url = url.replace(this.$config.ASSET_URL, this.$config.CDN_URL)
        // url = url.replace('/uploads', this.$config.ASSET_URL)
        return url
      } else {
        return /^((http|https):\/\/)/.test(url) ? url : 'https://' + url // this.$config.API_URL + url
      }
    },
  },
}
