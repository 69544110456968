import { gql } from 'graphql-tag'
import { LinkFragment } from './parts'
import { MenuFragment } from './menu'

export const CookiesFragment = gql`
  fragment CookiesFragment on ComponentStructureCookies {
    policy {
      ...LinkFragment
    }
    GA4ID
  }
  ${LinkFragment}
`

export const FooterFragment = gql`
  fragment FooterFragment on ComponentStructureFooter {
    preheading
    heading
    body
    legalLinks {
      ...MenuFragment
    }
    copyright
  }

  ${LinkFragment}
  ${MenuFragment}
`

export const SocialsFragment = gql`
  fragment SocialsFragment on SocialRelationResponseCollection {
    data {
      attributes {
        icon
        link {
          ...LinkFragment
        }
      }
    }
  }
`

export const ShareLinksFragment = gql`
  fragment ShareLinksFragment on ShareLinkRelationResponseCollection {
    data {
      attributes {
        icon
        link {
          ...LinkFragment
        }
      }
    }
  }
`
