
import CookieConsent from '@/components/parts/CookieConsent.vue'
import PreLoader from '@/components/structure/PreLoader.vue'
export default {
  name: 'DefaultLayout',
  components: {
    CookieConsent,
    PreLoader,
    GridOverlay: () => import('@/components/structure/GridOverlay.vue'),
  },
  data() {
    return {
      preloader: true,
      loader: true,
      initial: true,
    }
  },
  computed: {
    isProduction: () => {
      return process.env.NODE_ENV === 'production'
    },
  },
  mounted() {
    this.$nuxt.$on('pageMounted', this.pageMounted)
  },
  beforeDestroy() {
    this.$nuxt.$off('pageMounted', this.pageMounted)
  },
  methods: {
    pageMounted() {
      this.$nextTick(() => {
        if (this.initial) {
          setTimeout(() => {
            this.animateIn()
            this.initial = false
          }, '300')
        } else {
          this.animateIn()
        }
      })
    },

    animateIn() {
      this.preloader = false
      const hash = this.$route.hash
      if (hash) {
        const el = document.querySelector(hash)
        if (el) window.scrollTo({ top: el.offsetTop, behavior: 'instant' })
      }
      this.$nextTick(() => {
        this.$nuxt.$emit('go')
      })
    },
  },
}
