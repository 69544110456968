
export default {
  name: 'DefaultLayout',
  components: {},
  data() {
    return {}
  },

  mounted() {
    this.$nuxt.$emit('go')
  },
}
