
export default {
  name: 'LinkPart',

  components: {},
  props: {
    link: {
      type: Object,
      default: () => {},
    },
    iconClass: {
      type: String,
      default: () => '',
    },
    srOnly: {
      type: Boolean,
      default: () => false,
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hover: false,
    }
  },

  computed: {
    isScrollTo() {
      if (this.link.url.match(/#[^\s,]+/g)) return true
      return false
    },
    linkProps() {
      if (this.isScrollTo) {
        return {
          tag: 'nuxt-link',
          to: { path: this.$route.fullPath, hash: this.link.url },
          label: this.link.label,
        }
      }
      if (!this.link.url) {
        return {
          tag: 'span',
        }
      }
      if (this.link.url.match(/^(http(s)?|ftp):/) || this.link.url.match(/mailto:/gm)) {
        return {
          tag: 'a',
          link: this.link.url,
          target: '_blank',
          rel: 'noopener',
        }
      }
      return {
        tag: 'nuxt-link',
        to: this.link.url,
      }
    },
  },
  methods: {
    navigate(e) {
      if (this.isScrollTo) {
        if (this.$route.path !== '/') {
          this.$router.push({ path: '/', hash: this.link.url })
        } else {
          // this.scroll()
          return e
        }
      } else {
        return e
      }
    },
    scroll() {
      // const linkST = this.$ScrollTrigger.create({
      //   trigger: this.link.url,
      //   start: 'top bottom',
      // })
      // this.$gsap.to(window, { duration: 1, scrollTo: linkST.start, ease: 'custom.1' })
    },
  },
}
