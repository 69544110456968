import globalQuery from '@/apollo/queries/global'

const parseMenuItems = (raw) => {
  // parse links to match LinkPart props
  const parsed = []
  raw.forEach((item) => {
    parsed.push({
      label: item.attributes.title,
      url: item.attributes.page?.data?.attributes?.slug ? `/${item.attributes.page.data.attributes.slug}` : item.attributes.url,
      target: item.attributes.target,
      order: item.attributes.order,
      // page: item.attributes.page,
    })
  })
  return parsed.sort((a, b) => a.order - b.order)
}

export const state = () => ({
  global: null,
})

export const mutations = {
  SET_GLOBAL(state, value) {
    state.global = value
  },
}
export const getters = {
  getNavbar: (state) => {
    const navbar = {}
    if (state.global?.mainMenu?.data?.attributes?.items?.data) {
      navbar.menu = parseMenuItems(state.global.mainMenu.data.attributes.items.data)
    }
    if (state.global?.socials?.socials?.data) navbar.socials = state.global.socials.socials.data
    if (state.global?.contact) navbar.contact = state.global.contact
    return navbar
  },

  getFooter: (state) => {
    let footer = {}
    if (state.global?.footer) {
      footer = { ...state.global?.footer }
      if (state.global?.footer?.legalLinks?.data?.attributes?.items?.data) {
        footer.menu = parseMenuItems(state.global.footer.legalLinks.data.attributes.items.data)
      }
      // if (state.global?.socials?.socials?.data) footer.socials = state.global.socials.socials.data
      // if (state.global?.contact) footer.contact = state.global.contact
    }
    return footer
  },
  getAnalytics: (state) => {
    let analytics = null
    if (state?.global?.analytics) analytics = { ...state?.global?.analytics }
    return analytics
  },

  getPosts: (state) => {
    let posts = []
    if (state?.global?.posts) posts = state.global.posts
    return posts
  },

  // getShareLinks: (state) => {
  //   let shareLinks = []
  //   if (state.global?.shareLinks?.share_links?.data) shareLinks = state.global.shareLinks.share_links.data
  //   return shareLinks
  // },

  getSocials: (state) => {
    let socials = []
    if (state.global?.socials?.socials?.data) socials = state.global.socials.socials.data
    return socials
  },
}

export const actions = {
  async nuxtServerInit({ commit }, { route, payload }) {
    const client = this.app.apolloProvider.defaultClient
    if (payload?.global) {
      const global = payload.global
      // set store
      if (global) {
        commit('SET_GLOBAL', global)
      }
    } else {
      let global = null
      const globalResponse = await client.query({ query: globalQuery })
      if (globalResponse?.data?.global?.data) {
        global = globalResponse.data.global.data.attributes
      }
      if (global) {
        commit('SET_GLOBAL', global)
      }
    }
  },
}
