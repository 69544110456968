import { extend } from 'vee-validate'
/* eslint-disable */
import { required, email, alpha_spaces, ext, size, max } from 'vee-validate/dist/rules.umd.js'

extend('required', {
  ...required,
  message: 'Field required',
})
extend('email', {
  ...email,
  message: 'Valid email required',
})
extend('alpha_spaces', alpha_spaces)
extend('ext', ext)
extend('size', {
  ...size,
  message: 'Over maximum size',
})
extend('max', {
  ...max,
  message: '2048 Characters maximum',
})
extend('url', (value) => {
  if (!value) return true
  try {
    new URL(value)
    return true
  } catch (err) {
    return false
  }
})

extend('max', max)
